import { Checkbox, FormControlLabel, Typography } from '@mui/material';

import { Controller } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import { TextField, Button } from '@ourtilt/tilt-ui-components';
import { theme } from 'stitches.config';

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '100%',
});

const AuthCodeForm = ({
  redirectFn,
  handleSubmit,
  control,
  errors,
  otherError,
  isSubmitting,
  dirtyFields,
  watch,
  buttonLabel,
  isLoading,
  disabled,
}: {
  redirectFn: (any) => void;
  handleSubmit?: any;
  control?: any;
  errors?: Record<string, any>;
  otherError?: any;
  isSubmitting?: boolean;
  dirtyFields?: Record<string, any>;
  watch?: any;
  buttonLabel: string;
  isLoading?: boolean;
  disabled?: boolean;
}) => {
  const authCodeValue = watch('authCode');
  return (
    <Form onSubmit={handleSubmit(redirectFn)}>
      <Controller
        name="authCode"
        control={control}
        render={({ field }) => (
          <>
            <TextField
              id="authCode"
              error={!!errors?.authCode}
              helperText={errors?.authCode?.message}
              {...field}
              onChange={(e) => {
                field.onChange(e);
              }}
              name="authCode"
              sx={{
                width: '100%',
                mt: 2,
              }}
              title="Enter 6-digit code"
              disabled={disabled || isSubmitting || isLoading}
              autoComplete="off"
            />
            {otherError && <Typography color="error">{otherError}</Typography>}
          </>
        )}
      />
      <Controller
        name="rememberDevice"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={
              <Checkbox
                id="rememberDevice"
                checked={!!field.value}
                {...field}
                disabled={disabled || isSubmitting || isLoading}
                sx={(theme) => ({
                  color: theme.palette.grey[300],
                  '& .MuiSvgIcon-root': {
                    fontSize: '34px',
                  },
                })}
              />
            }
            label="Remember this device"
          />
        )}
      />
      <Button
        fullWidth
        variant="contained"
        color="primary"
        disabled={isSubmitting || isLoading || authCodeValue?.length !== 6 || !dirtyFields.authCode}
        type="submit"
        sx={{
          mb: 3,
        }}
      >
        {buttonLabel}
      </Button>
    </Form>
  );
};

export default AuthCodeForm;
