import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

const useLoginForm = () => {
  const schema = yup
    .object({
      email: yup.string().email('Must be a valid email.').required('Email is a required field.'),
      password: yup.string().required('Password is a required field'),
    })
    .required();

  const {
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitting, dirtyFields },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const watchAll = watch();

  return {
    watchAll,
    dirtyFields,
    control,
    handleSubmit,
    errors,
    Controller,
    isSubmitting,
  };
};

export default useLoginForm;
