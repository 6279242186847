import { routes } from '@constants/routes';
import NextLink, { LinkProps } from 'next/link';
import NextRouter, { useRouter } from 'next/router';
import React, { Children, MouseEventHandler } from 'react';

import { getSubdomain } from './utils/subdomain';

let subdomain;
if (typeof window !== 'undefined') {
  subdomain = getSubdomain();
}

// Why this exists? Check out: server/main.ts
// Basically all routes in admin are internally prefixed with /admin
// but not visible in the URL
export const getHref = (href) => (subdomain === 'admin' ? `/admin${href === '/' ? '' : href}` : href);

export const replaceAdminInPath = (path) => {
  if (!path.includes('/admin')) return path;
  return path.replace(/\/?admin\b/, '');
};

export const getLoginRedirectUrl = (): { path: string | null; backTo: string | null } => {
  if (typeof window === 'undefined') {
    return { path: routes.AUTH.LOGIN, backTo: '' };
  }

  if (window.location.pathname.includes(routes.AUTH.LOGIN) || window.location.pathname.includes(routes.AUTH.RESET)) {
    // do not redirect when on auth password pages
    return { path: null, backTo: null };
  }

  if (window.location.search?.startsWith('?backTo')) {
    return { path: routes.AUTH.LOGIN, backTo: window.location.search };
  }

  const originalUrl = window.location.pathname + window.location.search;
  const backTo = originalUrl === '/' ? '' : `?backTo=${encodeURIComponent(originalUrl)}`;

  return { path: routes.AUTH.LOGIN, backTo: backTo };
};

interface ILinkProps {
  href: string;
  children: any;
  as?: string;
  shallow?: boolean;
  className?: string;
  title?: string;
}

export const Link = ({ href, as: _as, children, ...props }: ILinkProps) => {
  const child = Children.only(children);
  const as = typeof _as === 'undefined' ? href : _as;

  const onLinkClicked: MouseEventHandler<Element> = (...props) => {
    child.props.onClick?.(...props);
  };

  return (
    <NextLink href={getHref(href)} as={as || '/'} {...props} legacyBehavior>
      {React.cloneElement(child, {
        onClick: onLinkClicked,
      })}
    </NextLink>
  );
};

interface INavLinkProps extends LinkProps {
  children: any;
  className?: string;
  activeClassName?: string;
  exact?: boolean;
  absolute?: boolean;
}
/**
 * Detect the active link and assign a class to it
 */
export const NavLink = ({ href, children, activeClassName, as, exact = false, absolute = false }: INavLinkProps) => {
  const { asPath } = useRouter();
  const child = Children.only(children);
  const _href = absolute ? href : getHref(href);
  const _pathname = getHref(asPath);

  let className = child.props.className || '';
  if (exact ? _pathname === _href : _pathname.includes(_href.toString())) {
    className = `${className} ${activeClassName}`.trimEnd();
  }

  const onLinkClicked: MouseEventHandler<Element> = (...props) => {
    child.props.onClick?.(...props);
  };

  const _as = typeof as === 'undefined' ? href : as;

  return (
    <NextLink href={_href} as={_as} legacyBehavior>
      {React.cloneElement(child, {
        className,
        onClick: onLinkClicked,
      })}
    </NextLink>
  );
};

export const Router = {
  push: (href, _as?, ...other) => {
    const as = typeof _as === 'undefined' ? href : _as;
    NextRouter.push(getHref(href), as || '/', ...other);
  },
  replace: (href, _as?, ...other) => {
    const as = typeof _as === 'undefined' ? href : _as;
    NextRouter.push(getHref(href), as || '/', ...other);
  },
  back: (...args) => {
    (NextRouter.back as any)(args);
  },
  router: NextRouter.router,
  routes,
};

export default {
  Link,
  NavLink,
  Router,
};
