import { Box, Divider, Stack, styled, Typography, TypographyProps } from '@mui/material';
import { ICompanyVM } from '@types';
import LoginHeader from './LoginHeader';
import LoginLayout from '@layouts/LoginLayout';
import { Controller } from 'react-hook-form';
import { Button, TextField } from '@ourtilt/tilt-ui-components';
import { Link } from 'router';
import { routes } from '@constants/routes';
import GoogleSignInButton from '../GoogleSignInButton';
import RipplingSigninButton from '../RipplingSigninButton';
import useLoginForm from 'hooks/useLoginForm';
import { isInstallFlowSubdomain } from '@utils/rippling-install';
import LoginFooterLinks from './LoginFooterLinks';

const ForgotPasswordLink = styled(Typography)<TypographyProps>(({ theme }) => ({
  display: 'block',
  textAlign: 'left',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(4),
  color: theme.palette.primary.main,
  textDecoration: 'underline',
  cursor: 'pointer',
}));

const LoginForm = ({
  company,
  isLoadingSsoResponse,
  ssoLogin,
  onLogin,
  otherError,
  isLoggingIn,
  loginDisabled = false,
}: {
  company: ICompanyVM;
  isLoadingSsoResponse: boolean;
  onLogin: (p: any, isGoogleLogin?: boolean) => void;
  ssoLogin: () => void;
  otherError?: string | undefined;
  isLoggingIn?: boolean | undefined;
  loginDisabled?: boolean;
}) => {
  const { control, errors, isSubmitting, handleSubmit, dirtyFields } = useLoginForm();
  return (
    <LoginLayout>
      <Box component="form" sx={{ width: '100%' }} onSubmit={handleSubmit((values) => onLogin(values, false))}>
        <LoginHeader title="Welcome!" text="Log in to your Tilt account and manage your leaves." />
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              id="email"
              fullWidth
              sx={{ mb: 3 }}
              error={!!errors.email?.message}
              helperText={errors.email?.message}
              {...field}
              name="email"
              title="Work Email"
              autoComplete="email"
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              id="password"
              error={!!errors.password?.message}
              helperText={errors.password?.message}
              {...field}
              name="password"
              title="Password"
              type="password"
              autoComplete="off"
              InputLabelProps={{ sx: { mt: 2 } }}
            />
          )}
        />
        <Link href={routes.AUTH.FORGOT_PASSWORD}>
          <ForgotPasswordLink component="a">Forgot password?</ForgotPasswordLink>
        </Link>
        <Button
          loading={isSubmitting || isLoggingIn}
          disabled={isSubmitting || isLoggingIn || !(dirtyFields.email && dirtyFields.password) || loginDisabled}
          variant="contained"
          color="primary"
          fullWidth
          type="submit"
          sx={{ mb: 2 }}
        >
          Log in
        </Button>
        <Divider sx={{ my: 2 }}>
          <Typography color="text.secondary" sx={{ fontWeight: 800 }}>
            OR
          </Typography>
        </Divider>

        <Stack spacing={2} direction="column" sx={{ width: '100%' }}>
          <GoogleSignInButton onLogin={(values) => onLogin(values, true)} disabled={loginDisabled} />
          {!isInstallFlowSubdomain() && company?.ripplingSsoEnabled && (
            <RipplingSigninButton disabled={isLoadingSsoResponse || loginDisabled} onLogin={ssoLogin} />
          )}
        </Stack>

        {otherError && (
          <Typography color="error" sx={{ mt: 2 }}>
            {otherError}
          </Typography>
        )}
      </Box>
      <Box sx={{ mt: 4 }}>
        <LoginFooterLinks />
      </Box>
    </LoginLayout>
  );
};

export default LoginForm;
