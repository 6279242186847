import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { Button, TextField } from '@ourtilt/tilt-ui-components';
import { Controller } from 'react-hook-form';

import LoginHeader from './LoginHeader';
import SubdomainFooterLinks from './SubdomainFooterLinks';
import LoginLayout from '@layouts/LoginLayout';
import useApi from '@api/transportLayer';

const Form = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 400,
}));

const SubdomainField = styled(Box)({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
});

const DomainText = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  color: theme.palette.grey[500],
  marginTop: theme.spacing(3),
}));

const ErrorText = styled('p')(({ theme }) => ({
  color: theme.palette.error.main,
  marginTop: theme.spacing(1),
}));

const SubdomainForm = ({
  control,
  handleSubmit,
  redirectFn,
  loginError,
  isSubmitting,
  dirtyFields,
  setLoginError,
  errors,
}: {
  control: any;
  handleSubmit: any;
  redirectFn: (any) => void;
  loginError?: string | undefined;
  setLoginError?: (val: string) => void;
  errors?: Record<string, any>;
  isSubmitting?: boolean;
  dirtyFields?: Record<string, any>;
}) => {
  const { data } = useApi.Auth.getEnv();
  const domainText = `.${data?.SITE_DOMAIN || 'hellotilt.com'}`;
  return (
    <LoginLayout>
      <Form onSubmit={handleSubmit(redirectFn)}>
        <LoginHeader title="Log in" />
        <Controller
          name="subdomain"
          control={control}
          render={({ field }) => (
            <SubdomainField>
              <TextField
                id="subdomain"
                error={errors?.subdomain?.message}
                {...field}
                onChange={(vals) => {
                  setLoginError('');
                  field.onChange(vals);
                }}
                name="subdomain"
                title="Enter your organization's subdomain"
                placeholder="subdomain"
                sx={{
                  mt: 1,
                  mb: 2,
                  '& input': {
                    minWidth: 250,
                  },
                }}
                autoComplete="on"
              />
              <DomainText>{domainText}</DomainText>
            </SubdomainField>
          )}
        />
        {loginError && (
          <Typography variant="body1" color="error">
            {loginError}
          </Typography>
        )}
        <Button
          loading={isSubmitting}
          color="peacock"
          disabled={isSubmitting || !dirtyFields?.subdomain}
          sx={{ mt: 2 }}
          type="submit"
        >
          Continue
        </Button>
      </Form>
      <SubdomainFooterLinks />
    </LoginLayout>
  );
};

export default SubdomainForm;
