import React from 'react';
import { Box, Typography } from '@mui/material';
import LoginLayout from 'layouts/LoginLayout';
import LoginHeader from './LoginHeader';
import QRCodeDisplay from '@screens/LoginPage/TwoFactorPage/components/Totp/QRCode';
import HelpCenterLink from './HelpCenterLink';

interface TOTPVerificationFormProps {
  children: React.ReactNode;
  qrCodeValue?: string;
  backFn?: () => void;
}

const TOTPVerificationForm = ({ children, qrCodeValue, backFn }: TOTPVerificationFormProps) => {
  return (
    <LoginLayout>
      <LoginHeader title="Set up your 2-Step Verification" back={backFn} />
      <Box sx={{ textAlign: 'center', my: 3 }}>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Install an Authenticator App to generate secure 2-step Verification codes, and scan the QR Code
        </Typography>
        <QRCodeDisplay qrCodeValue={qrCodeValue} />
      </Box>
      {children}
      <HelpCenterLink />
    </LoginLayout>
  );
};

export default TOTPVerificationForm;
