import { Button, styled } from '@mui/material';
import { Controller } from 'react-hook-form';
import LoginLayout from 'layouts/LoginLayout';
import LoginHeader from './LoginHeader';
import HelpCenterLink from './HelpCenterLink';
import { TextField } from '@ourtilt/tilt-ui-components';

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  width: '100%',
  maxWidth: '400px',
  margin: '0 auto',
});

const FindSubdomainForm = ({
  control,
  handleSubmit,
  redirectFn,
  subdomainLinkError,
  isSubmitting,
  dirtyFields,
  setSubdomainLinkError,
  errors,
  router,
  isFindingSubdomain,
}: {
  control: any;
  handleSubmit: any;
  router?: Record<any, any> | undefined;
  redirectFn: (any) => void;
  subdomainLinkError?: string | undefined;
  setSubdomainLinkError?: (val: string) => void;
  errors?: Record<string, any>;
  isSubmitting?: boolean;
  dirtyFields?: Record<string, any>;
  isFindingSubdomain?: boolean | undefined;
}) => {
  return (
    <LoginLayout>
      <Form onSubmit={handleSubmit(redirectFn)}>
        <LoginHeader
          back={() => router.back()}
          title="We are here to help!"
          text="If you don't know your company's subdomain, don't worry. Give us your work email and we will figure it out for you."
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              id="email"
              fullWidth
              error={Boolean(subdomainLinkError || errors.email?.message)}
              helperText={subdomainLinkError || errors.email?.message}
              {...field}
              onChange={(e) => {
                setSubdomainLinkError('');
                field.onChange(e.target.value);
              }}
              name="email"
              label="Work Email"
              autoComplete="email"
            />
          )}
        />
        <Button
          variant="contained"
          disabled={isSubmitting || isFindingSubdomain || !dirtyFields.email}
          type="submit"
          sx={{
            backgroundColor: '#4A90E2',
            '&:hover': {
              backgroundColor: '#357ABD',
            },
          }}
        >
          {isSubmitting || isFindingSubdomain ? 'Loading...' : 'Submit'}
        </Button>
      </Form>
      <HelpCenterLink />
    </LoginLayout>
  );
};

export default FindSubdomainForm;
