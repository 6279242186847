import { styled, Typography, Button } from '@mui/material';
import React from 'react';
import Image from 'next/legacy/image';

import ripllingImage from '@public/assets/rippling-button-grey.svg';

interface IProps {
  onLogin: (p: any) => void;
  className?: string;
  disabled?: boolean;
}

const RipplingButton = styled(Button)(({ theme }) => ({
  margin: 0,
  padding: 0,
  border: 'none',
  backgroundColor: 'transparent',
  boxShadow: theme.shadows[2],
}));

const RipplingSigninButton = ({ onLogin, disabled, className }: IProps) => {
  return (
    <RipplingButton disableElevation type="button" disabled={disabled} onClick={onLogin}>
      <Image alt="Tilt Logo" src={ripllingImage} objectFit="cover" />
    </RipplingButton>
  );
};

export default RipplingSigninButton;
