import TiltLogo from '@public/logo.svg';
import loginGraphic from '@public/assets/login-graphic.svg';
import React from 'react';
import Image from 'next/legacy/image';
import { Box, styled } from '@mui/material';

type IProps = {
  children: React.ComponentProps<any>;
};

const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100vh',
  display: 'grid',
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: '600px auto',
  },
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr',
    backgroundColor: theme.palette.common.white,
  },
}));

const FormContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6.25, 12.5),
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  alignItems: 'flex-start',
}));

const Graphic = styled(Box)(({ theme }) => ({
  border: 'none',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.grey[100],
  '& img': {
    width: '95%',
  },
  [theme.breakpoints.up('xl')]: {
    '& img': {
      width: '75%',
    },
  },
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

const LoginLayout = ({ children }: IProps) => {
  return (
    <Container>
      <FormContainer>
        <Image alt="Tilt Logo" src={TiltLogo} width={75} height={43} objectFit="contain" />
        {children}
      </FormContainer>
      <Graphic>
        <Image src={loginGraphic} alt="" objectFit="contain" />
      </Graphic>
    </Container>
  );
};

export default LoginLayout;
