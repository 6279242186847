import React from 'react';
import { Box, Link as MuiLink, Typography } from '@mui/material';
import HelpCenterLink from './HelpCenterLink';
import { isReservedSubdomain } from 'utils';
import { routes } from '@constants/routes';

const LoginFooterLinks = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      {!isReservedSubdomain() && (
        <Typography>
          First time signing in? <MuiLink href={routes.AUTH.ACTIVATE}>Activate Account</MuiLink>
        </Typography>
      )}
      <HelpCenterLink />
    </Box>
  );
};

export default LoginFooterLinks;
