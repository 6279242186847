import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import HelpCenterLink from './HelpCenterLink';

const SubdomainFooterLinks = () => {
  return (
    <Box
      sx={(theme) => ({ display: 'flex', marginTop: theme.spacing(5), flexDirection: 'column', gap: theme.spacing(1) })}
    >
      <Typography>
        <Link
          href="/find-subdomain"
          sx={{
            textDecoration: 'underline',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          Find your subdomain
        </Link>{' '}
        using your work email.
      </Typography>
      <HelpCenterLink />
    </Box>
  );
};

export default SubdomainFooterLinks;
