import React from 'react';
import { Controller } from 'react-hook-form';
import { RadioGroup, FormControlLabel, FormLabel, Box, styled } from '@mui/material';
import LoginLayout from '@layouts/LoginLayout';
import LoginHeader from './LoginHeader';
import HelpCenterLink from './HelpCenterLink';
import { Button, Radio } from '@ourtilt/tilt-ui-components';

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '400px',
  margin: '0 auto',
});

const TwoFactorIgnoreForm = ({
  redirectFn,
  handleSubmit,
  control,
  backFn,
  isSubmitting,
  setValue,
}: {
  redirectFn: (data: any) => void;
  control: any;
  backFn: () => void;
  handleSubmit: any;
  isSubmitting?: boolean;
  setValue?: any;
}) => {
  const buildOptions = () => {
    const options = [
      {
        id: 'setUpNow',
        name: 'ignorePrompt',
        label: 'Set up now',
        value: 'setUpNow',
      },
      { id: 'remindLater', name: 'ignorePrompt', label: 'Remind me later', value: 'remindLater' },
      { id: 'notInterested', name: 'ignorePrompt', label: `I'm not interested`, value: 'notInterested' },
    ];
    return options;
  };

  return (
    <LoginLayout>
      <Form onSubmit={handleSubmit(redirectFn)}>
        <LoginHeader back={backFn} title="Set up your 2-Step Verification" />
        <Controller
          name="ignore2faPrompt"
          control={control}
          render={({ field }) => (
            <Box>
              <FormLabel sx={{ color: 'text.primary' }}>
                Protecting your account is our biggest priority. Set up 2 step verification to keep your account more
                secure.
              </FormLabel>
              <RadioGroup
                {...field}
                sx={{ mt: 3 }}
                onChange={(e) => {
                  setValue('ignore2faPrompt', e.target.value, { shouldDirty: true });
                }}
              >
                {buildOptions().map((option) => (
                  <FormControlLabel key={option.id} value={option.value} control={<Radio />} label={option.label} />
                ))}
              </RadioGroup>
            </Box>
          )}
        />
        <Button sx={{ mt: 3, mb: 3 }} variant="contained" color="primary" disabled={isSubmitting} type="submit">
          Next
        </Button>
      </Form>
      <HelpCenterLink />
    </LoginLayout>
  );
};

export default TwoFactorIgnoreForm;
