import React from 'react';
import { Box, styled, Typography, ButtonProps } from '@mui/material';
import { Button } from '@ourtilt/tilt-ui-components';

import FeatherIcon from '@components/FeatherIcon';

const BackButton = styled(Button)<ButtonProps>(({ theme }) => ({
  padding: 0,
  marginBottom: theme.spacing(1),
  fontWeight: 400,
  fontSize: 14,
  textTransform: 'none',
  '& svg': {
    width: 14,
    strokeWidth: 2.5,
  },
}));

const LoginHeader = ({
  back,
  title,
  text,
}: {
  title: string;
  text?: string | undefined;
  back?: () => void | undefined;
}) => {
  return (
    <Box
      sx={(theme) => ({
        marginTop: '21vh',
        marginBlockEnd: theme.spacing(1),
        mb: 6,
      })}
    >
      {back && (
        <BackButton onClick={back} variant="text" startIcon={<FeatherIcon name="ChevronLeft" />}>
          Back
        </BackButton>
      )}
      <Typography variant="h4" sx={(theme) => ({ color: theme.palette.grey[600] })}>
        {title}
      </Typography>
      {text && (
        <Typography variant="body1" sx={(theme) => ({ color: theme.palette.grey[500] })}>
          {text}
        </Typography>
      )}
    </Box>
  );
};

export default LoginHeader;
