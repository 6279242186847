import { Box, Link, LinkProps, Typography, styled } from '@mui/material';
import { ETwoFactorType } from '@types';
import HelpCenterLink from './HelpCenterLink';

const FooterText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[400],
  fontSize: theme.typography.body2.fontSize,
  marginBottom: theme.spacing(2),
}));

const ResendLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<LinkProps & { disabled: boolean }>(({ theme, disabled }) => ({
  color: disabled ? theme.palette.text.disabled : theme.palette.primary.main,
  cursor: disabled ? 'not-allowed' : 'pointer',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: disabled ? 'none' : 'underline',
  },
}));

const TwoFactorVerifyFooterLinks = ({
  resendFn,
  tokenType,
  disabled,
}: {
  resendFn: () => void;
  tokenType: string;
  disabled: boolean;
}) => {
  return (
    <Box>
      {tokenType === ETwoFactorType.SMS && (
        <FooterText>
          Didn't get the message?{' '}
          <ResendLink component="button" disabled={disabled} onClick={disabled ? undefined : resendFn}>
            Resend
          </ResendLink>
        </FooterText>
      )}
      <HelpCenterLink />
    </Box>
  );
};

export default TwoFactorVerifyFooterLinks;
