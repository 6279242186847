import React from 'react';
import { Typography, Link } from '@mui/material';

import { LOGIN_SUPPORT_PAGE } from '@constants/index';

const HelpCenterLink = () => (
  <Typography className="footerLink">
    Need help? Visit our{' '}
    <Link
      href={LOGIN_SUPPORT_PAGE}
      target="_blank"
      rel="noreferrer"
      sx={{
        textDecoration: 'underline',
      }}
    >
      Help Center
    </Link>
  </Typography>
);

export default HelpCenterLink;
