import { Box, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { Button, TextField } from '@ourtilt/tilt-ui-components';
import LoginHeader from './LoginHeader';
import LoginLayout from '@layouts/LoginLayout';
import HelpCenterLink from './HelpCenterLink';

const ActivateForgotScreen = ({
  control,
  handleSubmit,
  redirectFn,
  isSubmitting,
  dirtyFields,
  router,
  errors,
  otherError,
}: {
  control: any;
  handleSubmit: any;
  router?: Record<any, any> | undefined;
  redirectFn: (any) => void;
  errors?: Record<string, any>;
  isSubmitting?: boolean;
  dirtyFields?: Record<string, any>;
  otherError?: string | undefined;
}) => {
  return (
    <LoginLayout>
      <Box component="form" sx={{ width: '100%' }} onSubmit={handleSubmit(redirectFn)}>
        <LoginHeader
          back={() => router.back()}
          title="Let us help you"
          text="Enter your work email and, if you have an account, we'll send you instructions on setting your Tilt password."
        />
        <Controller
          name="workEmail"
          control={control}
          render={({ field }) => (
            <TextField
              id="workEmail"
              fullWidth
              sx={{ mb: 3 }}
              error={!!errors?.workEmail?.message}
              helperText={errors?.workEmail?.message}
              {...field}
              name="workEmail"
              title="Work Email"
              autoComplete="email"
            />
          )}
        />
        {otherError && (
          <Typography color="error" sx={{ mt: 2 }}>
            {otherError}
          </Typography>
        )}
        <Button
          loading={isSubmitting}
          disabled={isSubmitting || !dirtyFields.workEmail}
          variant="contained"
          color="primary"
          fullWidth
          type="submit"
          sx={{ mb: 2, '&.Mui-disabled': { pointerEvents: 'auto', cursor: 'not-allowed' } }}
        >
          Submit
        </Button>
      </Box>
      <HelpCenterLink />
    </LoginLayout>
  );
};

export default ActivateForgotScreen;
