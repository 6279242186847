import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import LoginLayout from '@layouts/LoginLayout';
import { Button, Icon, TextField } from '@ourtilt/tilt-ui-components';
import { EInputType } from '@components/v2/Input/Input';
import FeatherIcon from '@components/FeatherIcon';
import LoginHeader from './LoginHeader';
import HelpCenterLink from './HelpCenterLink';
import { styled, Typography } from '@mui/material';

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  width: '100%',
  maxWidth: '400px',
  margin: '0 auto',
});

const ChangePasswordForm = ({
  handleSubmit,
  redirectFn,
  control,
  errors,
  isSubmitting,
  dirtyFields,
  otherError,
}: {
  control: any;
  handleSubmit: any;
  redirectFn: (any) => void;
  errors?: Record<string, any>;
  isSubmitting?: boolean;
  dirtyFields?: Record<string, any>;
  otherError?: string | undefined;
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  return (
    <LoginLayout>
      <Form onSubmit={handleSubmit(redirectFn)}>
        <LoginHeader
          title="Set My Password"
          text="Passwords must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter, at least 1 number, and 1 special character (i.e. !, $, #, *)."
        />
        <Controller
          name="newPassword"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              id="newPassword"
              type={showPassword ? EInputType.TEXT : EInputType.PASSWORD}
              error={errors?.newPassword?.message}
              {...field}
              onChange={(vals) => {
                field.onChange(vals);
              }}
              helperText={errors?.newPassword?.message}
              name="newPassword"
              title="New Password"
              autoComplete="off"
              slotProps={{
                input: {
                  endAdornment: (
                    <Icon sx={{ '& svg': { fontSize: '1.0rem' } }}>
                      <FeatherIcon
                        onClick={() => setShowPassword((prev) => !prev)}
                        name={showPassword ? 'Eye' : 'EyeOff'}
                      />
                    </Icon>
                  ),
                },
              }}
            />
          )}
        />
        <Controller
          name="newPasswordConfirm"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              id="newPasswordConfirm"
              type={showPasswordConfirmation ? EInputType.TEXT : EInputType.PASSWORD}
              error={errors?.newPasswordConfirm?.message}
              {...field}
              onChange={(vals) => {
                field.onChange(vals);
              }}
              helperText={errors?.newPasswordConfirm?.message}
              name="newPasswordConfirm"
              title="Confirm Password"
              autoComplete="off"
              slotProps={{
                input: {
                  endAdornment: (
                    <Icon sx={{}}>
                      <FeatherIcon
                        onClick={() => setShowPasswordConfirmation((prev) => !prev)}
                        name={showPasswordConfirmation ? 'Eye' : 'EyeOff'}
                      />
                    </Icon>
                  ),
                },
              }}
            />
          )}
        />
        {otherError && (
          <Typography variant="body1" color="error">
            {otherError}
          </Typography>
        )}
        <Button
          loading={isSubmitting}
          color="peacock"
          disabled={isSubmitting || !(dirtyFields.newPassword && dirtyFields.newPasswordConfirm)}
          type="submit"
          sx={{
            mt: 3,
            mb: 4,
          }}
        >
          Set Password
        </Button>
      </Form>
      <HelpCenterLink />
    </LoginLayout>
  );
};

export default ChangePasswordForm;
