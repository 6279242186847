import React from 'react';

import LoginLayout from '@layouts/LoginLayout';
import { ETwoFactorType } from '@types';
import LoginHeader from './LoginHeader';

const TwoFactorVerifyForm = ({
  children,
  router,
  maskedPhone,
  tokenType,
}: {
  children: any;
  router?: Record<any, any> | undefined;
  maskedPhone?: string;
  tokenType: string;
}) => {
  const headerText = tokenType === ETwoFactorType.SMS ? `A 6-digit code was sent to ${maskedPhone}` : null;
  return (
    <LoginLayout>
      <LoginHeader title="2 Step Verification" text={headerText} back={() => router.reload()} />
      {children}
    </LoginLayout>
  );
};

export default TwoFactorVerifyForm;
