import { GoogleLogin } from '@react-oauth/google';
import React from 'react';
import { toast } from 'react-hot-toast';

interface IProps {
  onLogin: (p: any) => void;
  disabled?: boolean;
}

const GoogleSignInButton = ({ onLogin, disabled = false }: IProps) => {
  const onLoginSuccess = async (response) => {
    await onLogin({ idToken: response.credential });
  };

  return (
    <div style={disabled ? { pointerEvents: 'none' } : {}}>
      <GoogleLogin
        onSuccess={onLoginSuccess}
        onError={() => {
          toast.error('There was an error signing in with Google. Please contact Tilt support.');
        }}
        type={'standard'}
        theme={'outline'}
        size={'large'}
        width={400}
        text={'continue_with'}
      />
    </div>
  );
};

export default GoogleSignInButton;
