import { Box, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import LoginLayout from 'layouts/LoginLayout';
import LoginHeader from './LoginHeader';
import HelpCenterLink from './HelpCenterLink';
import { styled } from '@mui/material/styles';
import { Button, TextField } from '@ourtilt/tilt-ui-components';

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '100%',
  maxWidth: '400px',
  margin: '0 auto',
});

const ErrorText = styled(Typography)({
  color: 'red',
  fontSize: '14px',
  marginTop: '4px',
});

const SMSVerificationForm = ({
  children,
  redirectFn,
  backFn,
  handleSubmit,
  control,
  errors,
  otherError,
  isSubmitting,
  dirtyFields,
}: {
  children: React.ReactNode;
  redirectFn: (data: any) => void;
  backFn: () => void;
  handleSubmit: any;
  control: any;
  errors?: Record<string, any>;
  otherError?: string;
  isSubmitting?: boolean;
  dirtyFields?: Record<string, any>;
}) => {
  return (
    <LoginLayout>
      <LoginHeader title="Set up your 2-Step Verification" back={backFn} />
      <Form onSubmit={handleSubmit(redirectFn)}>
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field }) => (
            <Box>
              <TextField
                {...field}
                id="phoneNumber"
                error={!!errors?.phoneNumber}
                helperText={errors?.phoneNumber?.message}
                fullWidth
                label="Enter your mobile phone to receive a secure code"
                type="tel"
                autoComplete="tel"
              />
              {otherError && <ErrorText>{otherError}</ErrorText>}
            </Box>
          )}
        />
        <Button
          sx={{ mb: 3 }}
          variant="contained"
          disabled={isSubmitting || !dirtyFields?.phoneNumber}
          type="submit"
          fullWidth
        >
          {isSubmitting ? 'Sending...' : 'Send'}
        </Button>
      </Form>
      {children}
      <HelpCenterLink />
    </LoginLayout>
  );
};

export default SMSVerificationForm;
