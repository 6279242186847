import { LoginHeader } from '@screens/LoginPage/LoginComponents';
import LoginLayout from '@layouts/LoginLayout';
import HelpCenterLink from './HelpCenterLink';
import { Box, styled } from '@mui/material';

const FormContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '400px',
});

const CheckEmailMessage = ({ text }: { text: string | undefined }) => {
  return (
    <LoginLayout>
      <FormContainer>
        <LoginHeader title="Check Your Email!" text={text} />
        <HelpCenterLink />
      </FormContainer>
    </LoginLayout>
  );
};

export default CheckEmailMessage;
