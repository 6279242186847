const RIPPLING_CODE = 'rippling-install-code';
const RIPPLING_REDIRECT_URI = 'rippling-install-redirect_uri';

export function clearRipplingInstallFlowStorage(companyUuid: string) {
  const redirectUri = window.localStorage.getItem(RIPPLING_REDIRECT_URI);
  window.localStorage.removeItem(RIPPLING_CODE);
  window.localStorage.removeItem(RIPPLING_REDIRECT_URI);
  return `${redirectUri}?id=${companyUuid}`;
}

export function setRipplingInstallFlowStorage(params) {
  window.localStorage.setItem(RIPPLING_CODE, params.code);
  window.localStorage.setItem(RIPPLING_REDIRECT_URI, params.redirect_uri);
}

export function retrieveRipplingInstallFlowValues() {
  return {
    code: window.localStorage.getItem(RIPPLING_CODE),
    redirectUri: window.localStorage.getItem(RIPPLING_REDIRECT_URI),
  };
}

export function isRipplingInstallFlow() {
  // TODO when switching to single subdomain we will need the "secure"
  // subdomain to remain unique to the install flow or a new check
  // will need to be done
  if (window.localStorage.getItem(RIPPLING_CODE) && window.localStorage.getItem(RIPPLING_REDIRECT_URI)) {
    return true;
  }
  return false;
}

export const isInstallFlowSubdomain = () => {
  if (typeof window !== 'undefined') {
    return window.location.hostname.startsWith('secure.');
  }

  return false;
};
